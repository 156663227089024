import { Dialog } from "@/components/ui/dialog";
import { useEffect, useState, useRef } from "react";
import { Toaster, toast } from "sonner";
import { CompanyDomainAutocompleteForFindSimilarDemo } from "@/components/workflow-inputs/companyWebsiteAutocomplete";
import { Button } from "@/components/ui/button";
import { FrontendCompany } from "@/services/autogen";
import { findSimilarCompaniesLight } from "@/services/brain-api.service";
import { CompanyListTableLight } from "@/components/company-lists/GiantTable/CompanyListTableLight";
import { useLoaderData } from "react-router-dom";
import posthog from "posthog-js";
import { isAxiosError } from "axios";
import { isValidUrl } from "@/lib/utils";
import { CompanyIntelligenceRequestButton } from "./LandingPage";

export function FindSimilarPage() {
  const [company, setCompany] = useState("");
  const [results, setResults] = useState<FrontendCompany[]>([]);

  const loaderData = useLoaderData() as { companyDomain: string } | null;

  const isInitialMount = useRef(true);

  const handleSubmit = async (company_domain_override?: string) => {
    const companyDomain = company_domain_override || company;
    posthog.capture("user_used_find_similar_companies_demo", {
      company_domain: companyDomain
    });
    if (!companyDomain || !isValidUrl(companyDomain)) {
      toast.error("Please enter a valid company domain");
      return;
    }

    try {
      setResults([]);
      toast.info("Searching for similar companies.. takes about 20 seconds");

      const userId = posthog.get_distinct_id();

      let timeoutId: NodeJS.Timeout | null = null;

      const toastTimeoutPromise = new Promise<void>((resolve) => {
        timeoutId = setTimeout(() => {
          toast.info("Sorry it's taking a while... we may be updating this company's record, might take another 60s or so...");
          resolve();
        }, 25000);
      });

      const fetchPromise = findSimilarCompaniesLight(companyDomain, userId);

      const result = await Promise.race([fetchPromise, toastTimeoutPromise]);

      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      if (result === undefined) {
        // This means the toastTimeoutPromise resolved first
        // Now we wait for the fetchPromise to complete
        const fetchResult = await fetchPromise;
        setResults(fetchResult.data);
      } else {
        // The fetchPromise resolved before the timeout
        setResults(result.data);
      }
      toast.success("Search complete!");
    } catch (error) {
      if (isAxiosError(error) && error.response?.status === 429) {
        toast.error(
          <div className="flex flex-col items-center flex-wrap w-full">
            <div>Out of demo searches</div>
            <Button className="mt-4" size="sm" onClick={() => (window.location.href = "https://app.meticulate.ai")}>
              Try the research engine for free
            </Button>
          </div>
        );
      } else {
        toast.error("Failed to fetch similar companies");
      }
    }
  };

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      if (loaderData?.companyDomain && company !== loaderData.companyDomain) {
        setCompany(loaderData.companyDomain);
        handleSubmit(loaderData.companyDomain);
      }
    }
  }, [loaderData?.companyDomain]);

  return (
    <Dialog>
      <div className="flex flex-col dark relative min-h-screen font-outfit">
        <div className="h-15 bg-[rgba(255,255,255,0.56)] px-5 py-2 flex flex-row items-center backdrop-blur-md fixed z-50 w-full border-b border-light-border">
          <img
            src="/MeticulateIconSquare.svg"
            className="h-4 cursor-pointer"
            onClick={() => (window.location.href = "https://meticulate.ai")}
          />
          <div className="text-xl ml-1 text-colors-brand-700 font-medium">Meticulate</div>
          <div className="flex-grow"></div>
          <div className="flex flex-row items-center gap-2">
            <CompanyIntelligenceRequestButton />
            <Button
              className="w-30 h-10 bg-[#7F5EFF] hover:bg-[#8F5EFF] text-white shadow-[0_0_10px_rgba(255,255,255,0.5)] hover:shadow-[0_0_10px_rgba(255,255,255,0.6)]"
              onClick={() => {
                window.location.href = "https://app.meticulate.ai";
              }}>
              Login
            </Button>
          </div>
        </div>
        <div className="w-full relative" style={{ background: "linear-gradient(#bcbcc4, #c9c9d0 18%, #fff 40%)" }}>
          <img src="/glow.webp" className="absolute top-0 left-0 w-full opacity-100" />
          <div className="pt-40 p-4 flex flex-col w-full items-center md:justify-center relative z-10">
            <div className="md:text-5xl text-4xl text-black font-medium text-center max-w-[700px]">Meticulate - lookalike search</div>
            <div className="md:text-xl text-lg flex mt-5 mb-10 font-landing font-light text-colors-gray-modern-700 text-center opacity-80 max-w-[500px]">
              Find similar companies to a target
            </div>
            <div className="flex flex-row items-center gap-4 rounded-lg p-2 mb-5 relative z-50" style={{ transformStyle: "preserve-3d" }}>
              <CompanyDomainAutocompleteForFindSimilarDemo
                placeholder="Company domain"
                value={company}
                onChange={(e) => setCompany(e)}
                onSubmit={({ value }) => {
                  setCompany(value);
                }}
              />
              <Button type="submit" onClick={() => handleSubmit()} className="w-30 h-10 bg-[#7F5EFF] hover:bg-[#8F5EFF] text-white">
                Search
              </Button>
            </div>

            <div className="max-w-[min(90vw,1000px)] flex flex-col items-center relative">
              <CompanyListTableLight businesses={results} />
              {results != null && results.length > 0 && (
                <div className="absolute bottom-0 left-0 right-0 h-1/4 bg-gradient-to-b from-transparent to-white flex items-center justify-center z-50 rounded-md">
                  <Button
                    className="bg-[#7F5EFF] hover:bg-[#8F5EFF] text-white self-end mb-2"
                    onClick={() => (window.location.href = "https://app.meticulate.ai")}>
                    See More
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Toaster position="top-center" richColors />
    </Dialog>
  );
}
